<template>
  <div class="page">
    <div
      class="back-link"
      @click="$router.push({ name: 'Accounts' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter($t('words.accountsManagement')) }}
      </span>
    </div>
    <div class="top">
      <b-row>
        <b-col class="left-col">
          <span>
            <img
              src="@/assets/icons/company.svg"
              alt="Icon company"
            >
            {{ organisationsCount }} {{ $tc('words.organisation', organisationsCount) }}
          </span>
          <div
            class="add-btn"
            @click="$router.push({ name: 'AddOrganisation' })"
          >
            <b-icon-plus />
            {{ $t('organisations.create') }}
          </div>
        </b-col>
        <b-col class="right-col">
          <Multiselect
            v-model="organisationTypeFilter"
            :options="organisationsTypes"
            track-by="codename"
            label="display_name"
            select-label=""
            selected-label=""
            deselect-label=""
            :searchable="false"
            :placeholder="$t('forms.organisation.type')"
            @select="setOrganisationTypeFilter"
            @remove="setOrganisationTypeFilter"
          />
          <TableFilters
            :filter-name="'usergroup__status__in'"
            :choices="usergroupStatuses"
            style="margin-left: 1rem; margin-bottom: 1px;"
            @filter="setTableFilters"
          />
        </b-col>
      </b-row>
    </div>
    <SearchablePaginatedTable
      :name="'organisationsListTable'"
      :loading="loadingTable"
      :searchable="true"
      :search-placeholder="$t('organisations.search')"
      :search-function="SEARCH_ORGANISATIONS_LIST"
      :route-to="'OrganisationDetail'"
      :fields="fields"
      :rows="rows"
      :count="organisationsCount"
      :default-sorting="{
        sortedUp: false,
        sortedDown: true,
        sortedField: 'date_joined'
      }"
      :sort-function="GET_ORGANISATIONS_LIST"
      :selectable="false"
      @sorted="setTableSorted"
      @change-page="changeTablePage"
      @delete="openDeleteModal"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';
import TableFilters from '@/components/TableFilters.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Organisations',

  components: {
    Multiselect,
    SearchablePaginatedTable,
    TableFilters
  },

  data() {
    return {
      loadingTable: false,
      organisationToDelete: null,
      tableSorted: {
        direction: null,
        field: null
      },
      organisationTypeFilter: null
    };
  },

  computed: {
    ...mapState('table', [
      'currentTables'
    ]),
    ...mapState('organisations', [
      'organisationsList',
      'organisationsCount',
      'organisationsTypes',
      'isOrganisationsListSearched',
      'searchOrganisationsFilter'
    ]),
    ...mapState('usergroups', [
      'usergroupStatuses'
    ]),

    fields() {
      return [
        {
          key: this.$t('organisations.table.fields.organisation'),
          apiName: 'display_name',
          sortable: true,
          width: '45%',
          centered: false
        },
        {
          key: this.$t('organisations.table.fields.organisationType'),
          apiName: 'organisation_type',
          sortable: true,
          width: '15%',
          centered: false
        },
        {
          key: this.$t('organisations.table.fields.creationDate'),
          apiName: 'creation_date',
          sortable: true,
          width: '10%',
          centered: true
        },
        {
          key: this.$t('organisations.table.fields.usersCount'),
          sortable: false,
          width: '7%',
          centered: true
        },
        // {
        //   key: this.$t('organisations.table.fields.signedConvention'),
        //   sortable: false,
        //   width: '7%',
        //   centered: true
        // },
        // {
        //   key: this.$t('organisations.table.fields.convention'),
        //   sortable: false,
        //   width: '7%',
        //   centered: true
        // },
        {
          key: this.$t('organisations.table.fields.status'),
          sortable: false,
          width: '5%',
          centered: true
        },
        {
          key: this.$t('organisations.table.fields.delete'),
          sortable: false,
          width: '7%',
          centered: true
        }
      ];
    },
    rows() {
      return this.organisationsList.map(el => {
        return {
          id: el.id,
          [this.$t('organisations.table.fields.organisation')]: el.display_name,
          [this.$t('organisations.table.fields.organisationType')]: el.organisation_type ? el.organisation_type.display_name : '',
          [this.$t('organisations.table.fields.creationDate')]: new Date(el.creation_date).toLocaleString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }),
          [this.$t('organisations.table.fields.usersCount')]: el.usergroup.members_count,
          // [this.$t('organisations.table.fields.signedConvention')]: el.agreement_signed,
          // [this.$t('organisations.table.fields.convention')]: el.agreement_file ? el.agreement_file.url : null,
          [this.$t('organisations.table.fields.status')]: this.usergroupStatuses.find(item => item.choice === el.usergroup.status) ?
            this.usergroupStatuses.find(item => item.choice === el.usergroup.status).choice :
            '-1',
        };
      });
    }
  },

  created() {
    this.SET_TABLE({
      name: 'organisationsListTable',
      table: {
        loading: false,
        currentPage: this.currentTables.find(t => t.name === 'organisationsListTable') ?
          this.currentTables.find(t => t.name === 'organisationsListTable').currentPage :
          1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  mounted() {
    // Reset filters
    this.setTableFilters({
      filter: 'usergroup__status__in',
      values: this.usergroupStatuses.map(el => {
        return {
          ...el,
          state: el.choice === 2 ? true : false
        };
      })
    }, true);

    // Get data if data not loaded already filtered by search
    if (this.organisationsTypes.length === 0) {
      this.getConfigData();
    }
    if (this.organisationsList.length === 0 || this.isOrganisationsListSearched) {
      this.getData();
    }

    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.deletingOrganisation = false;
    });
  },

  methods: {
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapMutations('table', [
      'SET_TABLE',
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATIONS_LIST',
      'SEARCH_ORGANISATIONS_LIST',
      'GET_ORGANISATIONS_TYPES',
      'PRESET_ORGANISATIONS_FILTERS',
      'PREREMOVE_ORGANISATIONS_FILTERS',
      'DELETE_ORGANISATION'
    ]),

    getConfigData() {
      this.GET_ORGANISATIONS_TYPES();
    },

    getData() {
      this.loadingTable = true;
      this.GET_ORGANISATIONS_LIST({ ...this.tableSorted })
        .then(() => {
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    openDeleteModal(e) {
      this.organisationToDelete = e;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.deleteOrganisation.title'),
        content: this.$t('modals.deleteOrganisation.content', {
          org: this.organisationToDelete[this.$t('organisations.table.fields.organisation')]
        }),
        trigger: this.deleteOrganisation
      });
    },

    deleteOrganisation() {
      this.loadingTable = true;
      this.DELETE_ORGANISATION(this.organisationToDelete.id)
        .then(() => {
          this.loadingTable = false;
          this.getData();
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    changeTablePage(e) {
      this.loadingTable = true;
      this.GET_ORGANISATIONS_LIST({
        ...this.tableSorted,
        page: e
      })
        .then(() => {
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    setTableSorted(e) {
      this.tableSorted = e;
    },

    setTableFilters(e, reset = false) {
      for (const filter of e.values) {
        if (filter.state) {
          this.PRESET_ORGANISATIONS_FILTERS({
            filter: e.filter,
            value: filter.choice
          });
        } else {
          this.PREREMOVE_ORGANISATIONS_FILTERS({
            filter: e.filter,
            value: filter.choice
          });
        }
      }
      if (!reset) {
        this.getData();
      }
    },

    setOrganisationTypeFilter(e, reset = false) {
      if (this.organisationTypeFilter) {
        this.PRESET_ORGANISATIONS_FILTERS({
          filter: 'organisation_types',
          value: this.organisationTypeFilter.codename
        });
      } else {
        this.PREREMOVE_ORGANISATIONS_FILTERS({
          filter: 'organisation_types',
          value: e.codename
        });
      }
      if (!reset) {
        this.getData();
      }
    }
  }
};
</script>

<style lang="less" scoped>

.b-overlay {
  .position-absolute {
    display: flex;
  }
}

</style>
